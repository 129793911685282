.Investsubtitle {
  background: linear-gradient(316.64deg, #1a3e6d 0%, #2d6fc6 107.95%);
  padding: 32px 0;
  h2 {
    font-size: 1.5rem;
    color: $white;
  }
}

.investleftpanel {
  background: #e7ebff;
  border-radius: 16px;
  padding: 26px;
  margin-top: 25px;
  height: 100vh;
  .investcardinner {
    background: #fff;
    border-radius: 20px 20px 14px;
    .topimg {
      padding: 20px 20px 0;
    }
  }
  .investleftlist {
    font-size: 0.875rem;
    line-height: 24px;
    color: #2b2b2b;
    li {
      margin: 20px 0;
    }
  }
}

.investcardmain {
  margin-top: 25px;

  .investcardbox {
    background: $white;
    border-radius: 12px;
    padding: 20px;
    margin: 15px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 200px;
    overflow: hidden;
    .comingsoon {
      position: absolute;
      top: 20px;
      right: 0px;
      width: 120px;
    }
    img {
      width: 40px;
    }
    a {
      background: #ffffff;
      border: 1.54839px solid #005e9e;
      padding: 10px 24px 10px 28px;
      text-align: center;
      border-radius: 38px;
      color: #005e9e;
      font-size: 1rem;
      font-weight: 600;
      text-decoration: none;
      &:hover {
        background: #005e9e;
        color: #ffffff;
      }
    }
    span {
      flex: 1;
      color: #005e9e;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 27px;
      width: 100%;
      display: block;
      padding: 15px 0;
    }
    p {
      color: #363d4e;
      font-size: 0.75rem;
      line-height: 18px;
    }
  }
}
