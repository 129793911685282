.base_div {
  background: linear-gradient(318.22deg, #17325c 18.88%, #4c92dd 99.74%);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .logo {
    width: 120px;
  }

  .data {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .cloud-one {
      top: 40px;
      left: 0;

      @media (min-width: 992px) {
        top: 100px;
        transform: scale(1.5);
      }
    }

    .cloud-two {
      top: 80px;
      right: 1.5rem;

      @media (min-width: 992px) {
        top: 100px;
        transform: scale(1.5);
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      bottom: -5rem;

      @media (min-width: 992px) {
        bottom: 0;
      }

      .errorimg {
        width: 500px;
      }
    }
  }
}
.BOback_btn {
  background: #ffffff;
  border: 1px solid #1a3e6d;
  box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
  border-radius: 28px;
  font-weight: 600;
  font-size: 17px;
  color: #1a3e6d;
  padding: 0.5rem 1rem;
}

.internetdata {
  color: #fff;
  .NoInternetConnectionimg {
    width: 300px;
  }
  h2 {
    margin-top: 0.5rem;
    font-weight: bold;
    font-size: 1.25rem;
  }
  p {
    font-size: 1rem;
    line-height: 27px;
    text-align: center;
  }
}
