// @import "../modules/grid";
@import "../modules/colors";
@import "../modules/card";

body {
  .app-component {
    .app-router {
      .router-layout {
        .container {
          .wrapper {
            padding: 2% 5%;
            display: block;
            width: 100vw;
            min-height: calc(100vh - 10vh);
          }
        }
      }
    }
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
