@use "swiper/swiper.scss";
@use "swiper-global";

@use "./Slider/SlickGlobal.scss";
@use "./_mixins.scss" as *;

:root {
  --swiper-pagination-color: #1a3e6d;
}

.title-row {
  padding-bottom: 30px;
}

.title {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 27px;
  color: #263238;
  padding: 30px 0px 15px 0px;
  margin: 0px;
  @include break(lg) {
    color: #1a3e6d;
  }
}

.M_title {
  font-weight: 600;
  font-size: 1.125rem;
  color: #040c22;
  padding: 30px 0px 15px 0px;
  margin: 0px;
}

.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  box-shadow: none !important;
}

.error_text {
  font-size: 0.9375rem;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #c83532;
  margin-top: 0.5rem;
  max-width: 400px;
  font-weight: 400;
}

.text-btn {
  background: transparent;
  border: none;
  outline: none;
}

.logo-div {
  height: 70px;
  display: flex;
  align-items: center;
}

// Custom mui datepicker

.custom-datepicker {
  position: relative;

  .MuiInputAdornment-root {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

//dashbord bg-color
.base-main_bg {
  background: none;
  @include break(lg) {
    background: linear-gradient(to left, #ffffff 10%, #e5e5e5 100%);
  }
}
//dashbord left side color
.bg-dashbord {
  background-color: #e5e5e5;
}

// Toast
.MuiSnackbar-root {
  --gutter: 2rem;
  .MuiPaper-root {
    background: linear-gradient(134.14deg, #1a3e6d 0.61%, #2d6fc6 101.43%);
    color: #fff;
    padding: var(--gutter);
    position: relative;
  }

  .MuiSnackbarContent-action {
    position: absolute;
    top: var(--gutter);
    right: var(--gutter);
  }
}

.font-inter {
  font-family: "Inter" !important;
}

.text-underline {
  text-decoration: underline !important;
}

body.is-flutter {
  padding-bottom: 100px !important;
}
