// Variable overrides here

$font-family-sans-serif: "Poppins", system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$paragraph-margin-bottom: 0;
$headings-margin-bottom: 0;

$input-border-width: 2px;
$input-border-color: #d5d4dc;
$input-focus-color: #0f62fe;
$form-select-border-width: 2px;
$form-select-border-color: #d5d4dc;
$form-select-focus-border-color: #0f62fe;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-border-radius: 12px;
$form-select-box-shadow: none;
$form-select-focus-box-shadow: none;
$input-padding-y: 0.75rem;
$input-padding-x: 0.75rem;
$form-select-padding-y: 0.75rem;
$form-select-padding-x: 0.75rem;

$form-label-font-size: 0.8rem;
$form-label-font-weight: 600;
$form-label-color: #1a3e6d;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 7,
  10: $spacer * 8,
);

$container-padding-x: 1.5rem;

$accordion-border-width: 0;
$accordion-button-focus-box-shadow: none;

@import "~bootstrap/scss/bootstrap";
