.support-page-pc {
  background-color: #f2f3f7;
  .hero-bg {
    background-color: #f2f3f7;
    .hero-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 124px;
        height: auto;
      }
      h2 {
        font-weight: 600;
        font-size: 2.25rem;
        line-height: 54px;
        color: #1e3f6d;
        margin: 0.5rem 0rem;
      }
      .card-base-grid {
        display: flex;
        gap: 3rem;
        position: relative;
        .card-item {
          display: flex;
          align-items: center;
          padding: 1rem;
          background-color: #fff;
          border-radius: 15px;
          min-width: 327px;
          .icon-box {
            padding: 0.5rem;
            background-color: hsl(203, 100%, 97%);
            --size: 69px;
            height: var(--size);
            width: var(--size);
            border-radius: 50%;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1.5rem;

            img {
              --size: 32px;
              height: var(--size);
              width: var(--size);
            }
          }
          .text-group {
            span {
              color: #9a9aaf;
              font-weight: 500;
              font-size: 1rem;
              line-height: 24px;
            }
            a {
              text-decoration: none;
            }
            p {
              font-weight: 600;
              font-size: 1rem;
              line-height: 24px;
              color: #005e9e;
            }
          }
        }
      }
    }
    .cloud-bg {
      .cloud-O {
        position: absolute;
        top: 25%;
        left: 5%;
        width: 150px;
        transform: scaleX(-1);
      }
      .cloud-T {
        position: absolute;
        top: 18%;
        right: 5%;
        width: 150px;
      }
      .cloud-Tr {
        position: absolute;
        top: 55%;
        left: 18%;
        width: 80px;
        transform: scaleX(-1);
      }
      .cloud-F {
        position: absolute;
        top: 40%;
        right: 18%;
        width: 80px;
      }
    }
  }
}

.support-page-pc {
  .FAQ-Support-title {
    text-align: center !important;
  }

  .faq-desktop-section {
    background: transparent;

    .accordion {
      .accordion-item {
        background-color: transparent;
        .title {
          background-color: transparent;
        }

        .panel {
          background-color: transparent;
        }
      }
    }
  }
}
