.WB_headcard {
  background-image: url(../images/BG_wellbing.svg);
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top:3.5rem;
  z-index: 90;
  height: 100px;
  .Hcard_text {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 36px;
    color: #fff;
  }
}
//base
.base_card_We {
  padding: 0rem 5rem;
  background-color: #f2f3f7;
  overflow-x: hidden;
}

//healthgoals
.pchealthgoals {
  .content_pchg {
    display: flex;
    justify-content: space-between;
    .PCHG_Left_card {
      background-color: #0063a1;
      color: #fff;
      border-radius: 14px;
      padding: 3rem;
      padding-bottom: 2rem;
      .head_text_div {
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
        width: max-content;
        h2 {
          font-weight: 600;
          font-size: 1.3125rem;
          padding-bottom: 10px;
        }
      }
      p {
        margin-top: 0.75rem;
        font-size: 0.875rem;
        line-height: 24px;
      }
    }
    .PCHG_Right_card {
      margin: 0rem 0rem;
      display: flex;
      gap: 2rem;
      padding-left: 35px;
      .card-HG {
        background-color: #fff;
        border-radius: 10px;
        height: 100%;
        width: 100%;
        padding: 1rem 1.5rem;
        position: relative;
        display: flex;
        .data_card_HG {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          height: 100%;
          img {
            width: 35px;
          }
          p {
            font-weight: 500;
            font-size: 1rem;
            color: #005e9e;
            text-align: left;
            margin: 0px;
            white-space: nowrap;
            margin-top: 0.75rem;
            flex-grow: 1;
          }
          .HG-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background: #1a3e6d;
            box-shadow: 0px 4px 16px rgba(175, 175, 175, 0.25);
            border-radius: 45.3463px;
            font-weight: 600;
            font-size: 0.875rem;
            margin-top: 3rem;
            padding: 0.4rem 0.7rem;
            outline: none;
            border-style: none;
          }
        }
      }
    }
  }
}
.add-goal-text {
  color: #005e9e;
  font-weight: 500;
  font-size: 1rem;
}
//mobile healthgoals
.mvhealthgoals {
  display: none;
  .top_hg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .Mbtn {
      background: #ffffff;
      line-height: 1;
      border: 1.88943px solid #005e9e;
      box-shadow: 0px 4px 16px rgba(175, 175, 175, 0.25);
      border-radius: 99rem;
      color: #005e9e;
      font-weight: 600;
      font-size: 0.875rem;
      padding: 0.6rem 1rem;
    }
  }
  .MVHG_Left_card {
    background-color: #0063a1;
    color: #fff;
    border-radius: 14px;
    padding: 2.5rem;

    .head_text_div {
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
      width: max-content;
      h2 {
        font-weight: 600;
        font-size: 1.3125rem;
      }
    }
    p {
      margin-top: 0.75rem;
      font-size: 0.875rem;
      line-height: 21px;
    }
  }
}
.card-HG {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem;
  background-color: #fff !important;
  border-radius: 10px;
  width: 225px;

  .actions-holder {
    min-height: 40px;
    margin-top: 1rem;
  }

  .HG-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #1a3e6d;
    box-shadow: 0px 4px 16px rgba(175, 175, 175, 0.25);
    border-radius: 45.3463px;
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0.4rem 0.7rem;
    outline: none;
    border-style: none;
  }

  div {
    img {
      width: 34px;
    }
    p {
      font-weight: 500;
      font-size: 1rem;
      color: #005e9e;
      margin-top: 10px;
    }
  }
}
.close_icon {
  position: absolute;
  right: 20px;
  top: 20px;
}

//Health goal services css
.PCHS_Right_card {
  // display: flex;
  // gap: 1rem;
  .swiper {
    padding-left: 0px !important;
  }
  .PCHS_Right_card::-webkit-scrollbar {
    display: none;
  }
  .HS_card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .card_img {
      margin-bottom: 1rem;
      border-radius: 16px;
      img {
        width: 100%;
        height: 322px;
        object-fit: cover;
        border-radius: 16px;
      }
    }
    .card_title {
      font-weight: 600;
      font-size: 1.125rem;
      color: #292929;
      margin: 0;
    }
    .card_text {
      font-weight: 500;
      font-size: 1.125rem;
      color: #292929;
      margin: 0;
      text-align: left;
      height: 55px;
    }
  }
}
.MVhelthservices {
  display: none;
  .head_text_div {
    img {
      width: 100%;
    }
  }
  .sliderCard-HS-MV {
    .HS_card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .card-HG .card_img {
        margin-bottom: 1rem;
      }
      .card_title {
        font-weight: 600;
        font-size: 0.875rem;
        color: #292929;
        margin: 0;
        padding-top: 0.75rem;
      }
      .card_text {
        font-weight: 500;
        font-size: 0.875rem;
        color: #292929;
        margin: 0;
        text-align: left;
        padding-top: 0.5rem;
      }
    }
  }
}

//HealthArticles page
.HealthArticlesPC {
  .HA_card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .card_img {
      margin-bottom: 1rem;
      img {
        width: 450px;
        height: 235px;
        object-fit: cover;
        border-radius: 16px;
      }
    }
    .card_title {
      font-weight: 600;
      font-size: 1.125rem;
      color: #292929;
      margin: 0;
      padding-right: 0.8rem;
      text-align: left;
      height: 60px;
      overflow: hidden;
    }
    .HA_readbtn {
      text-decoration: none;
      font-weight: 600;
      font-size: 1.125rem;
      color: #005e9e;
    }
  }
}
.HealthArticlesMV {
  .HA_card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .card_img {
      margin-bottom: 1rem;
      // width: 435px;
      // height: 276px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .card_title {
      font-weight: 600;
      font-size: 0.9375rem;
      color: #292929;
      margin: 0;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      text-align: left;
    }
    .HA_readbtn {
      text-decoration: none;
      font-weight: 600;
      font-size: 0.8125rem;
      color: #005e9e;
      padding-left: 0.8rem;
      margin-top: 2rem;
    }
  }
}
//HealthScore css
.pchealthgoals_pc {
  height: max-content;
  width: 100%;
  .healthscore_card {
    background-image: url(../images/healthscore_card_bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    gap: 4rem;
    width: 100%;
    height: 250px;
    padding: 2.5rem 2rem 2.5rem 3rem;
    border-radius: 20px;
    .scroe_text {
      h1 {
        font-weight: bold;
        font-size: 4.4435rem;
        color: #16f6ff;
        margin-bottom: 0px;
      }
      .base-line {
        width: 60px;
        height: 1px;
        background-color: #fff;
        margin-left: 5px;
      }
      p {
        font-weight: 600;
        font-size: 21.8136px;
        color: #fff;
        margin-top: 10px;
        white-space: nowrap;
      }
    }
    .mid_text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        color: #fff;
        width: 70%;
        font-weight: 300;
        font-size: 1rem;
      }
      .down_text {
        display: flex;
        gap: 2rem;
        .number_text {
          color: #fff;
          margin-top: 0.2rem;
          p {
            font-weight: bold;
            font-size: 1.883675rem;
          }
          span {
            font-weight: 300;
            font-size: 1.28835625rem;
          }
        }
      }
    }
    .btn-div {
      display: flex;
      align-items: flex-end;
      button {
        white-space: nowrap;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 17px;
        color: #2e98a4;
        background: #ffffff;
        border-radius: 26.4378px;
        padding: 0.5rem 1.3rem;
        border-style: none;
        outline: none;
      }
    }
  }
}

.exclusivefeaturesPC {
  .exclusivefeaturesPC_slider_card {
    width: 100%;
    position: relative;
    .text_div {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 3rem;
      left: 2rem;
      align-items: flex-start;
      .main_text {
        font-weight: 600;
        font-size: 1.6752125rem;
        line-height: 40px;
        color: #fff;
        text-align: left;
        margin-bottom: 10px;
        height: 50px;
      }
      p {
        width: 73%;
        font-weight: normal;
        font-size: 0.83760625rem;
        line-height: 20px;
        color: #fff9f9;
        text-align: left;
        height: 44px;
        overflow: hidden;
      }
      .card_btn {
        margin-top: 12px;
        background: #005e9e;
        box-shadow: 0px 5.9563px 11.9126px -4.46723px rgba(0, 82, 103, 0.32);
        border-radius: 28px;
        color: #fff;
        padding: 0.5rem 1rem;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 24px;
        text-decoration: none;
        outline: none;
        border-style: none;
      }
    }

    .bg_img {
      top: 0;
      z-index: -1;
    }
  }
}

.exclusivefeaturesMV_slider_card {
  display: grid;
  width: 100%;

  .text_div {
    grid-area: 1/-1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 3rem 2rem;
    z-index: 3;
    .main_text {
      font-size: 1.5rem;
      font-weight: 600;
      color: #fff;
    }

    p {
      font-weight: normal;
      font-size: 0.83760625rem;
      line-height: 20px;
      color: #fff9f9;
      text-align: left;
      margin: 0.75rem 0;
    }

    .card_btn {
      background: #1a3e6d;
      box-shadow: 0px 5.9563px 11.9126px -4.46723px rgba(0, 82, 103, 0.32);
      border-radius: 28px;
      color: #fff;
      padding: 0.5rem 1rem;
      font-weight: 600;
      font-size: 0.875rem;
    }
  }

  .bg_img {
    grid-area: 1/-1;
    top: 0;
  }
}

.PhysicalWellbeingPC {
  .plancard_base {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    .plancard_iteam {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;
      height: 100%;
      background: #fff;
      border-radius: 16px;
      .left_text_card {
        h4 {
          font-weight: 600;
          font-size: 2.25rem;
          line-height: 54px;
          color: #005e9e;
        }
        p {
          font-weight: normal;
          font-size: 1rem;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.9);
          width: 90%;
        }
      }
      .right_text_card {
        h3 {
          font-weight: 600;
          font-size: 3.4375rem;
          line-height: 79px;
          color: #005e9e;
          white-space: nowrap;
        }
      }
    }
  }
}
.PhysicalWellbeingMV {
  .plancard_base {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    .plancard_iteam {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      gap: 1rem;
      background: linear-gradient(134.14deg, #1a3e6d 0.61%, #2d6fc6 101.43%);
      border-radius: 16px;
      .left_text_card {
        h4 {
          font-weight: 600;
          font-size: 2.25rem;
          line-height: 54px;
          color: #fff;
        }
        p {
          font-weight: normal;
          font-size: 1rem;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .right_text_card {
        h3 {
          font-weight: 600;
          font-size: 65.3684px;
          line-height: 79px;
          color: #fff;
        }
      }
    }
  }
}
.PhysicalWellbeing_sider {
  .img_slider {
    height: 250px;

    img {
      border-radius: 16px 16px 0px 0px;
      object-fit: cover !important;
    }
  }
  .down_text {
    background-color: #fff;
    border-radius: 0px 0px 16px 16px;
    height: 150px;
    display: flex;
    h2 {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 30px;
      text-align: left;
      padding: 1rem;
      height: 100px;
      overflow: hidden;
    }
  }
}
.PhysicalWellbeing_sider_MV {
  box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.15);
  .img_slider {
    background-image: url("https://picsum.photos/200");
    width: 100%;
    height: 200px;
    background-size: cover;
    border-radius: 16px 16px 0px 0px;
  }
  .down_text {
    background-color: #fff;
    border-radius: 0px 0px 16px 16px;
    overflow: hidden;
    display: flex;
    h2 {
      font-weight: 600;
      font-size: 1rem;
      line-height: 30px;
      text-align: left;
      padding: 1rem;
    }
  }
}

.Plan_card_item_mobile {
  background: #fff;
  border-radius: 0.75rem;
  padding: 1.5rem;

  .header {
    font-size: 1.375rem;
    font-weight: 600;
    flex-shrink: 0;
    flex-basis: 48%;
  }

  .description {
    flex-basis: 48%;
    p {
      font-size: 0.8125rem;
      font-weight: 400;
    }

    .join-btn-mv {
      text-decoration: none;
      border: 1.8px solid #005e9e;
      display: inline-block;
      font-size: 0.75rem;
      font-weight: 600;
      padding: 0.475rem 1.5rem;
      background: #fff;
      color: #005e9e;
      border-radius: 99rem;
      margin-top: 14px;
    }
  }

  &:nth-child(2) {
    background: linear-gradient(134.14deg, #1a3e6d 0.61%, #2d6fc6 101.43%);
    border-radius: 1rem;
    color: #fff;
  }
}

.OtherSlider {
  &.mobileView {
    .other-slider-title {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 1.12rem;
    }

    .thumbnail {
      .coming-soon {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 0.625rem;
        font-weight: 500;
        background: #c4f1fd;
        color: #005e9e;
        padding: 2px 1rem;
        border-radius: 3px;
      }
    }
  }
}

.OtherSlider_img {
  img {
    width: 50px;
  }
  h2 {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 27px;
    color: #263238;
  }
}

.EmotionalMentalWellbeing_card_base {
  background-image: url(../images/EmotionalandMentalWellbeing.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 250px;
  padding: 2.5rem 0rem 0rem 3.5rem;
  border-radius: 20px;
  .money-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 4.0625rem;
    line-height: 79px;
  }
  .self-text {
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 54px;
    color: #ffffff;
  }
}
.EmotionalMentalWellbeing_card_base_MV {
  background-image: url(../images/EmotionalMentalWellbeing_card_base_MV.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 180px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  text-align: left;
  padding: 1rem 2rem;
  h2 {
    font-weight: 600;
    font-size: 1.25rem;
    color: #ffffff;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    p {
      font-weight: 600;
      font-size: 1.5rem;
      color: #fff;
      margin-bottom: 0px;
    }
    button {
      background: #ffffff;
      border-radius: 26px;
      padding: 0.5rem 0.75rem;
      outline: none;
      border-style: none;
      color: #005e9e;
      font-weight: 600;
      font-size: 0.875rem;
    }
  }
}

.healthscoreMV {
  align-items: center;
  .bg_healthscore {
    background-image: url(../images/HS_bg_img.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 16px;
    .top-text {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      h2 {
        font-weight: bold;
        font-size: 2rem;
        color: #16f6ff;
        margin: 0;
        padding-right: 1rem;
      }
      .rightline {
        width: 1px;
        height: 50px;
        background-color: rgba(255, 255, 255, 0.4);
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        color: #ffffff;
        margin: 0;
      }
    }
    .mid-text {
      color: #fff;
      h2 {
        font-weight: bold;
        font-size: 1.3125rem;
        color: #ffffff;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(#fff, 0.2);
      }
      p {
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 18px;
        padding-top: 1rem;
      }
    }
    .score_text {
      color: #fff;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      .bs-text {
        display: flex;
        gap: 1rem;
      }
      p {
        font-size: 0.875rem;
        line-height: 21px;
        margin: 0;
      }
      h2 {
        font-weight: bold;
        font-size: 1.5rem;
      }
    }
    .scrore_btn {
      outline: none;
      border-style: none;
      background: #ffffff;
      border-radius: 26px;
      color: #2e98a4;
      font-weight: 600;
      font-size: 0.875rem;
      padding: 0.5rem 1.5rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .mvhealthgoals {
    display: block;
  }
  .pchealthgoals {
    display: none;
  }
  .WB_headcard {
    display: none;
  }
  .base_card_We {
    padding: 0rem 1.5rem;
  }
  .PCHS_Right_card {
    display: none;
  }
  .MVhelthservices {
    display: block;
  }
  .pchealthgoals_pc {
    display: none;
  }
  .exclusivefeaturesPC {
    display: none;
  }
  .PhysicalWellbeingPC {
    display: none;
  }
}
.PRW {
  padding-right: 1rem !important;
  padding: 0px;
}
.img-group {
  position: absolute;
  right: 0;
}

.commingsoon-tag {
  color: #005e9e;
  font-weight: 500;
  font-size: 1.03375625rem;
  line-height: 25px;
  padding: 5px 35px;
  background: #c4f1fd;
  border-radius: 5.51335px;
  width: max-content;
}
.commingsoon-tag-other {
  color: #005e9e;
  font-weight: 500;
  font-size: 1.03375625rem;
  line-height: 25px;
  padding: 5px 35px;
  background: #c4f1fd;
  border-radius: 5.51335px;
  width: max-content;
  position: absolute;
  top: 100px;
}
