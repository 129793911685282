@use "mixins" as *;

@use "webapp-variables";

// Products Section
.products-container {
  //   padding: 0px 40px;
  background-color: #fff;
  .products-section {
    background: $powderblue;
    border-radius: 16px;
    padding-bottom: 60px;
    padding-top: 60px;
    margin: 0 2.5rem;
    .title-row {
      padding-bottom: 0px;
      .title-text {
        @include title(32px, 48px, 600, $darkblue);
      }
    }
    .explore-btn {
      background-color: transparent;
      color: #1a3e6d;
      font-weight: 600;
      border: 1px solid #1a3e6d;
      border-radius: 99rem;
      padding: 15px 60px;
      box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
    }

    .products-grid-desktop {
      grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
      gap: 2.25rem;
      .products-box {
        position: relative;
        min-width: 190px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 35px;

        @include break(xl) {
          grid-template-columns: repeat(5, 1fr);
        }
        .products-detail {
          background: #ffffff;
          padding: 36px 15px;
          border-radius: 15px;
          overflow: hidden;
          filter: drop-shadow(0px 37px 29px rgba(213, 213, 213, 0.2));
          .comingsoon-img {
            position: absolute;
            top: -1px;
            right: 0px;
            width: 90px;
          }
          .product-img {
            --size: 60px;
            width: var(--size);
            height: var(--size);
            margin-top: 8px;
            margin-left: auto;
            margin-right: auto;
          }
          .products-category {
            color: $lightblue;
            font-size: 1rem;
            line-height: 24px;
            font-weight: 600;
            padding: 15px 0px 0px 0px;
            margin-bottom: 0px;
          }
          .products-distext {
            color: $lightblue;
            font-size: 0.875rem;
            line-height: 24px;
            font-weight: 400;
            padding: 10px 0px 0px 0px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

// Services Section
.services-section {
  background: #fff;
  .explore-btn {
    background-color: transparent;
    color: #1a3e6d;
    font-weight: 600;
    font-size: 0.75rem;
    border: 1px solid #1a3e6d;
    border-radius: 99rem;
    padding: 8px 15px;
    box-shadow: 0px 0px 16px -6px rgba(0, 82, 103, 0.32);
    width: max-content;
    &:hover {
      color: #fff;
    }
  }

  @include break(lg) {
    padding: 82px 0;
  }

  .title-text {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #1e3f6d;

    @include break(lg) {
      font-size: 2.25rem;
    }
  }

  .slick-slider {
    background: #fff;
    position: relative;
  }

  .slick-list {
    padding: 0 2rem !important;
  }

  .slick-slide {
    & > div {
      margin: 0 0.5rem;
    }
  }

  .slick-dots {
    position: absolute;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 0;
    bottom: -3rem;
    margin-bottom: 0;
    width: 100%;

    li + li {
      margin-left: 16px;
    }

    li {
      button {
        border: none;
        outline: none;
        --size: 8px;
        height: var(--size);
        width: var(--size);
        background-color: #cfcfcf;
        border-radius: 50%;
      }

      &.slick-active {
        button {
          --size: 12px;
          background: #1a3e6d;
        }
      }
    }
  }

  //   Slider Block Mobile

  .swiper-slide {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
  }

  .slider-block {
    background: #fff;
    padding: 30px 20px;

    border-radius: 16px;

    .services-detail {
      img {
        display: block;
        --size: 132px;
        height: var(--size);
        width: var(--size);
        border-radius: 50%;
        object-fit: cover;
        margin: 0 auto 26px auto;
      }

      .services-title {
        font-size: 1.125rem;
        font-weight: 600;
        color: #1e3f6d;
      }

      .services-description {
        font-size: 0.875rem;
        line-height: 1.5;
        font-weight: 400;
        color: #808285;
      }

      .services-link {
        display: inline-block;
        font-weight: 600;
        font-size: 0.875rem;
        color: #1a3e6d;
        text-decoration: underline;
        margin-top: 14px;
      }
    }
  }

  .services-detail {
    display: block;
    .services-title {
      font-size: 1.125rem;
      font-weight: 600;
      color: #1e3f6d;
    }
    .services-description {
      @include title(16px, 27px, normal, $lightgray);
      display: block;
      padding-top: 5px;
      padding-bottom: 8px;
      max-width: 80%;
    }
    .services-link {
      @include title(17px, 24px, normal, $darkblue);
    }
  }
}

// User Saying Section
.Users-section {
  padding: 50px 0;
  @include break(lg) {
    padding: 110px 0;
  }
  .title-row {
    .title-text {
      font-size: 1.125rem;
      font-weight: 600;
      color: #1e3f6d;

      @include break(lg) {
        font-size: 2.25rem;
        margin-bottom: 50px;
      }
    }
  }

  .slick-list {
    padding: 0 2rem 0 0 !important;

    @include break(lg) {
      padding: initial;
    }
  }

  .slick-dots {
    list-style: none;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;

    li {
      & + li {
        margin-left: 1rem;
      }

      button {
        border: 0;
        outline: none;
        font-size: 0;
        --size: 8px;
        height: var(--size);
        width: var(--size);
        border-radius: 50%;
        background: #cfcfcf;
      }

      &.slick-active {
        button {
          --size: 12px;
          background: #1a3e6d;
        }
      }
    }
  }

  .users-slider {
    .users-block {
      background: #f5e2ba;
      border-radius: 16px;
      padding: 28px;
      margin-right: 1.5rem;
      .saying {
        font-size: 0.875rem;
        line-height: 30px;
        font-weight: 500;
        color: $darkgray;
      }
      .user-information {
        padding-top: 50px;

        .user-image {
          img {
            display: block;
            --size: 48px;
            height: var(--size);
            width: var(--size);
            border-radius: 50%;
          }
        }
        .user-detail {
          padding-left: 15px;
          .user-name {
            font-size: 0.875rem;
            font-weight: 500;
            margin-bottom: 5px;
          }
          .user-position {
            font-size: 0.625rem;
            font-weight: 400;
          }
        }
      }
    }
  }
}

// Retirment Section
.retirment-section {
  @include break(lg) {
    background-color: #fff;
  }
  .retirement-section-inner {
    position: relative;

    .app-art {
      position: absolute;
      z-index: 3;
      right: 10px;
      top: 25px;

      @include break(lg) {
        transform: translateY(-81%);
      }
      img {
        display: block;
        width: 150px;

        @include break(lg) {
          width: 495px;
          margin-bottom: -462px;
          margin-right: 5rem;
        }
      }
    }

    .app-ad-card {
      background: linear-gradient(318.22deg, #17325c 18.88%, #4c92dd 99.74%);
      border-radius: 20px;
      position: relative;
      padding: 24px 30px;
      color: #fff;
      overflow: hidden;

      @include break(lg) {
        padding: 75px 120px;
        overflow: visible;
      }

      .curve-art {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        top: -9rem;
        left: -5rem;

        @include break(lg) {
          top: 0rem;
          left: 0rem;
          width: 197px;
        }
      }

      .aac-title {
        font-weight: 600;
        font-size: 1.5rem;
        position: relative;
        z-index: 1;
        max-width: 480px;

        @include break(lg) {
          font-size: 3.4375rem;
        }
      }

      .aac-download-app {
        .ada-title {
          font-weight: 400;
          font-size: 0.96875rem;
          margin-bottom: 0.4rem;

          @include break(lg) {
            font-size: 1.125rem;
            margin-bottom: 1rem;
          }
        }

        .download-links-holder {
          a {
            flex-shrink: 0;
            img {
              height: 22px;
              object-fit: cover;

              @include break(lg) {
                height: 50px;
              }
            }
          }
        }
      }
    }
  }
}

.accordion-item {
  background-color: transparent;
}
.M_title {
  display: none;
}
@media only screen and (max-width: 767px) {
  .accordion-item .title {
    display: block !important;
  }
  .title {
    display: none;
  }
  .M_title {
    display: block;
  }
}

//gif
.gif-scrolltwo {
  bottom: 40px;
}

.gif_scroll {
  position: relative;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  img {
    width: 50px;
    margin: 0 auto;
  }
  p {
    color: #ffffff;
    font-size: 0.75rem;
  }
}

.slider-two {
  position: relative;
  .home-gif {
    position: absolute;
    left: 23%;
    top: 18%;
    width: 100px;
  }
}

.BGW {
  background-color: #fff;
}
