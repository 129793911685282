@mixin Myaccount-card($background) {
  box-shadow: 0px 17.6805px 70.7219px -35.3609px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  background: $background;
  margin-bottom: 30px;
}

.my-account-dashbaord {
  background: #e5e5e5;
  padding: 20px 0px;

  .myaccount-component {
    .persone-card {
      @include Myaccount-card(#005e9e);
      padding: 20px 25px;
      position: relative;
      overflow: hidden;
      .person-pattern-img {
        position: absolute;
        top: 0px;
        left: -29px;
        content: "";
        width: 167px;
      }
      .person-edit-block {
        position: relative;
        .persone-edit {
          position: absolute;
          top: 47px;
          right: -15px;
          width: 38px;
          height: 38px;
          .person-edit-btn {
            background: #ffffff;
            box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.25);
            border-radius: 50px;
            color: #005e9e;
            padding: 3px 5px 0px 6px;
            img {
              width: 16px;
              height: 16px;
              vertical-align: revert;
            }
          }
        }
      }
      .person-name {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 37px;
        color: #ffffff;
        text-transform: capitalize;
      }
      .persone-job {
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 21px;
        color: #ffffff;
      }
      .signed-text {
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 21px;
        text-transform: capitalize;
        color: #ffffff;
      }
      .signed-persone {
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 21px;
        text-transform: capitalize;
        color: #ffffff;
      }
      .logout-btn {
        background: #ffffff;
        box-shadow: 0px 9.85081px 19.7016px -7.38811px rgba(0, 82, 103, 0.32);
        padding: 3px 24px;
        font-weight: 600;
        font-size: 1rem;
        line-height: 30px;
        text-align: center;
        color: #005e9e;
        border-radius: 28px;
      }
    }
    .corporate-card {
      @include Myaccount-card(#ffffff);
      padding: 20px 25px;
      img {
        width: 48px;
      }
      .link-text {
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 24px;
        color: #005e9e;
      }
      .link-now-btn {
        background: #1a3e6d;
        box-shadow: 0px 7.2px 14.4px -5.4px rgba(0, 82, 103, 0.32);
        border-radius: 24px;
        color: #f6f7f8;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 20px;
        text-align: center;
        border: none;
        padding: 7px 20px;
      }
    }
    .myaccount-menus-card {
      @include Myaccount-card(#ffffff);
      box-shadow: 0px 17.6805px 70.7219px -35.3609px rgba(0, 0, 0, 0.16);
      padding: 1rem 0;

      .nav-item {
        .nav-link {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 0.9rem;
          line-height: 27px;
          color: #040c22;
          padding: 0.75rem 1.5rem;
          img {
            width: 32px;
            height: 32px;
            margin-right: 12px;
          }
        }

        .comingsoon-tag {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 90px;
        }

        .nav-link.active {
          color: #005e9e;
        }
      }
    }

    .myaccount-detail-form {
      @include Myaccount-card(#ffffff);
      box-shadow: 0px 21.8065px 87.2258px rgba(0, 0, 0, 0.1);
      border-radius: 21.8065px;
      padding: 30px 25px;
      .myacoount-heeader {
        .borderbottom {
          padding-bottom: 10px;
          border-bottom: 1px solid (rgba(0, 0, 0, 0.15));
        }
        .persone-icon {
          width: 36px;
          height: 36px;
        }
        .myaccount-title {
          font-weight: 600;
          font-size: 1.0625rem;
          line-height: 24px;
          color: #1a3e6d;
          padding-left: 15px;
          display: inline-block;
          margin-bottom: 0px;
        }
        .whatsapp-img {
          width: 26px;
          height: 26px;
        }
        .sharelink-text {
          font-weight: 600;
          font-size: 0.765625rem;
          line-height: 21px;
          color: #263238;
          padding-left: 10px;
        }
      }
    }

    .myaccount--main--form {
      padding: 15px 0px;
      .input-div {
        position: relative;
      }
      Label {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: #040c22;
        text-transform: capitalize;
      }
      .form-control,
      .form-select {
        // background: #ffffff;
        border-radius: 8px;
        font-weight: normal;
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #1a3e6d;
        position: relative;
        overflow: hidden;
      }
      .gender-all-btn {
        .gender-btn {
          background: #ffffff;
          border: 1.88943px solid #b0b9c1;
          border-radius: 45.3463px;
          margin-right: 15px;
          width: 13%;
          font-size: 0.875rem;
          line-height: 15px;
          text-align: center;
          letter-spacing: -0.258333px;
          color: #b0b9c1;
          width: max-content;
        }
        .gender-btn.active {
          background: #1a3e6d;
          border: 1.88943px solid #1a3e6d;
          color: #ffffff;
          font-weight: bold;
          width: max-content;
        }
      }

      .myaccount-btn {
        margin-top: 20px;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.4px;
        color: #f6f7f8;
        background: #1a3e6d;
        box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
        border-radius: 28px;
        padding: 10px 36px;

        :disabled {
          background-color: gray;
        }
      }
      .addmore,
      .combi-error {
        margin-bottom: 10px;
        .addmore-row {
          font-size: 14px;
          padding: 4px 20px;
        }
      }

      .kyc-checkbox {
        margin-left: 0px;
      }
      .CKYC-helptext {
        font-size: 0.6875rem;
        color: rgba($color: #000000, $alpha: 0.5);
      }
    }
    .referral-status {
      font-size: 14px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      border: 1px solid #ced4da;
      padding: 10px 20px;
      border-radius: 10px;
      span {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    .referfriend-main-form {
      .friend-image {
        width: 350px;
        display: inline;
      }
      .referefriend-desc {
        font-weight: normal;
        font-size: 1.375rem;
        line-height: 36px;
        text-align: center;
        letter-spacing: -0.3px;

        color: #263238;
      }
      .refere-btn {
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 36px;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.4px;
        color: #263238;
        padding: 10px 20px;
      }
    }
    .preinsurance-main-form {
      .insurance-desc {
        font-style: normal;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 27px;
        color: #263238;
      }
      .insurnace-block {
        background: #ffffff;
        box-shadow: 0px 21.8065px 87.2258px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 20px 25px;
        margin-top: 25px;
        .insurnace-header {
          border-bottom: 1px solid rgba(0, 0, 0, 0.14);
          padding-bottom: 10px;
          .insurence-bank {
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 27px;
            color: #005e9e;
            margin-bottom: 0px;
          }
          .insurnece-percentage {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 21px;
            color: #263238;
          }
        }
        .insurence-data {
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 24px;
          color: #263238;
          padding-top: 10px;
        }
        .insure-btn {
          background: #1a3e6d;
          box-shadow: 0px 7.23077px 14.4615px -5.42308px rgba(0, 82, 103, 0.32);
          border-radius: 28px;
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.361538px;
          text-transform: capitalize;
          color: #f6f7f8;
          padding: 7px 25px;
        }
      }
    }
  }
}

.Verify-account-component {
  background: linear-gradient(134.14deg, #1a3e6d 0.61%, #2d6fc6 101.43%);
  height: 100vh;
  padding: 50px 0px;
  .verifyemial-text {
    font-weight: bold;
    font-size: 2rem;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #f6f7f8;
  }
  p {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.2px;
    color: rgba(246, 247, 248, 0.72);
  }
  .backprofile-btn {
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
    border-radius: 28px;
    font-weight: 600;
    font-size: 1.0625rem;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #005e9e;
    margin-top: 20px;
    padding: 12px 40px;
  }
}

.back-section {
  padding-bottom: 15px;
  padding-top: 20px;
  img {
    padding-right: 20px;
    cursor: pointer;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 20px;
    color: #5c616f;
  }
}

// service corner grid
.service-corner-cards-cards-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 26px;

  .service-corner-card-item {
    background: #fff;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;

    .comin-soon-label {
      right: 0;
      top: 0.75rem;
    }

    .scci-header {
      padding: 40px 40px 20px 40px;
      align-items: flex-start;

      .icon {
        margin-right: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: none;
        box-shadow: none;

        img {
          --size: 65px;
          min-height: var(--size);
          min-width: var(--size);
        }
      }

      .scci-header-title {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 36px;
        color: #005e9e;
        height: 100px;
        overflow: hidden;
      }
    }

    .scci-content {
      padding: 40px 40px 20px 40px;
      border-top: 1px solid rgba(#000, 0.21);

      p {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 27px;
        color: #585858;
        height: 105px;
        overflow: hidden;
      }

      .sct-cta {
        outline: none;
        border: none;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: -0.25833332538604736px;
        background: #1a3e6d;
        color: #fff;
        padding: 0.75rem 1.5rem;
        margin-top: 1rem;
        border-radius: 99rem;
      }
      .commingsoon-tag-Sercor {
        color: #005e9e;
        font-weight: 500;
        font-size: 16.5401px;
        padding: 5px 5px;
        background: #c4f1fd;
        border-radius: 5.51335px;
        text-align: center;
        width: 100%;
        height: max-content;
        margin-top: 1rem;
      }
    }
  }
}

.Calculator-copmonent {
  background: #e5e5e5;
  .Calculator-Recommended-card {
    .row {
      row-gap: 30px;
    }
    .card-col {
      padding: 0 30px 0 0;
      .card-base {
        min-height: 320px;
        border-radius: 10px;
        padding: 20px 15px;
        box-shadow: -6.90596px 29.3503px 63.3046px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
        .card-text {
          color: #fff;
          position: relative;
          z-index: 9;
          & > h4 {
            font-size: 1.25rem;
            font-weight: 600;
          }
          & > p {
            margin-top: 10px;
            font-size: 0.875rem;
            font-weight: 400;
          }
        }
        .card-ill {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;
        }
        // invest Recommended card img css
        .Age-ill {
          width: 160px;
          transform: translate(-50%, -50%);
          top: 77%;
          left: 50%;
        }
        .Incometax-ill {
          width: 120px;
          transform: translate(-50%, -50%);
          top: 77%;
          left: 50%;
        }
        .Digitalhuman-ill {
          width: 160px;
          transform: translate(-50%, -50%);
          top: 81%;
          left: 50%;
        }
        .Lifefreedom-ill {
          width: 160px;
          transform: translate(-50%, -50%);
          top: 74%;
          left: 58%;
        }
        .RetiralCalculator-ill {
          width: 190px;
          transform: translate(-50%, -50%);
          top: 74%;
          left: 50%;
        }
        .NPSCalculator-ill {
          width: 160px;
          transform: translate(-50%, -50%);
          top: 77%;
          left: 50%;
        }
        .ImmunityCalculator-ill {
          width: 184px;
          transform: translate(-50%, -50%);
          top: 75%;
          left: 50%;
        }
        .Backbooklet-ill {
          width: 130px;
          transform: translate(-50%, -50%);
          top: 74%;
          left: 50%;
        }
      }
    }
  }
}

.ligainformation-componet {
  background: #f2f3f7;

  .ligalinfo {
    padding: 30px 0px;
    background: linear-gradient(316.64deg, #1a3e6d 0%, #2d6fc6 107.95%);
    position: relative;
    overflow: hidden;
    color: #ffffff;
    .legalinfo-text {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 36px;
      text-transform: capitalize;
      color: #ffffff;
    }
    .legalinfo-desc {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 24px;
      color: #ffffff;
    }
    .legalpattern-img {
      position: absolute;
      width: 150px;
      top: 0px;
      right: 0px;
    }
  }
  .legal-part-text {
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 27px;
    color: #000000;
    margin-bottom: 0px;
    padding: 15px 0px;
  }
  .legalinformation-tabs {
    .react-tabs__tab-list {
      background: #e9e9e9;
      padding: 10px 0px;
      border-bottom: none;
      .react-tabs__tab {
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 27px;
        text-transform: capitalize;
        color: #263238;
        padding-left: 0px;
        padding-right: 25px;
      }
      .react-tabs__tab--selected {
        border: none;
        background: transparent;
        color: #005e9e;
        font-weight: bold;
      }
    }
  }
}

.support-screen {
  background: #f2f3f7;
  .support-copmonent {
    background: #f2f3f7;
    padding: 30px 0px;
    .support-header-part {
      position: relative;
      overflow: hidden;
      .need-help-title {
        font-weight: 600;
        font-size: 2.25rem;
        line-height: 54px;
        text-align: center;
        color: #1e3f6d;
      }
      .support-card {
        background: #ffffff;
        border-radius: 11.7498px;
        padding: 15px 25px;
        margin-top: 30px;
        img {
          padding-right: 25px;
        }
        .support-name {
          padding-top: 10px;
          font-weight: 500;
          font-size: 1rem;
          line-height: 24px;
          color: #9a9aaf;
        }
        .supoprt-link {
          font-weight: 500;
          font-size: 1rem;
          color: #005e9e;
          text-decoration: none;
        }
      }
    }
    .Cloud1-img {
      position: absolute;
      position: absolute;
      top: 10px;
      left: 15px;
    }
    .Cloud2-img {
      position: absolute;
      position: absolute;
      top: 10px;
      right: 15px;
    }
    .Cloud3-img {
      position: absolute;
      position: absolute;
      top: 250px;
      left: 120px;
    }
    .Cloud4-img {
      position: absolute;
      position: absolute;
      top: 180px;
      right: 200px;
    }
  }
  .accordion-item {
    background-color: transparent;
  }
}

.myaccount-btn_out {
  margin-top: 20px !important;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  line-height: 24px !important;
  text-align: center !important;
  letter-spacing: -0.4px !important;
  color: #1a3e6d !important;
  background: #fff !important;
  box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32) !important;
  border-radius: 28px !important;
  padding: 10px 36px !important;
}

.table_span {
  font-weight: normal;
  font-size: 0.75rem;
  color: #000000;
}
.TR {
  text-align: right;
}
@media only screen and (max-width: 992px) {
  .my-account-dashbaord {
    background-color: #fff;
    height: 100%;
    overflow: scroll;
  }
  .my-profile-form {
    padding: 0rem 0.5rem;
    .head {
      display: flex;
      gap: 1rem;
    }
  }
}

.img-text-fix {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.NO-TF-text {
  color: #1a3e6d;
  font-weight: 600;
  font-size: 1.25rem;
}
.coporateDe-empty-state {
  .text-ES {
    color: #67839c;
    font-weight: 500;
    font-size: 1.125rem;
    text-align: center;
  }
  .ES-Btn {
    width: max-content;
    padding: 5px 86px;
  }
}

// signatureuploadbase
.signature-upload-base {
  background: #ebf7ff;
  border: 2px dashed #bcbaba;
  border-radius: 8px;
  .upload-link {
    color: #1a3e6d;
    font-weight: 500;
    font-size: 1.125rem;
    text-decoration: underline;
    text-align: center;
  }
}
.salary-font {
  font-size: 0.75rem;
}
