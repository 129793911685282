@use "./_mixins.scss" as *;

@use "fonts";
// Color & Font-Family
@import "./webapp-variables";

//slider
@import "./Slider/SliderMain";

// Landing Page
@import "./Landing";
@import "./Landing-mobile";
@import "./SignInUp";

//Wellbing page
@import "./Wellbeing";
@import "./InvestDashboard";
@import "./plan";
@import "./dashbord";
@import "./Myaccount";
@import "./Service";

.desktopView {
  display: block;
  @media only screen and (max-width: 991px) {
    display: none;
  }
}

.mobileView {
  display: none;
  @media only screen and (max-width: 992px) {
    display: block;
  }
}

.pcM0 {
  @media only screen and (max-width: 600px) {
    margin: 0px;
  }
}

.mvM0 {
  margin: 0px;
  @media only screen and (max-width: 600px) {
    display: block;
  }
}

.pcP0 {
  @media only screen and (max-width: 600px) {
    padding: 0px !important;
  }
}

.mvP0 {
  padding: 0px;
  @media only screen and (max-width: 600px) {
    display: block;
  }
}

#timer {
  display: inline;
  padding: 5px;
  color: #004853;
  font-family: Verdana, sans-serif, Arial;
  font-size: 2.5rem;
  font-weight: bold;
  text-decoration: none;
}
.link {
  text-decoration: none;
  cursor: pointer;
}

.mandatory {
  color: red;
}
.error {
  color: red;
}
.success {
  color: green;
}
.transition {
  transition: 0.3s;
}

.padding-top-dashbord {
  @include break(lg) {
    padding-top: 4.5rem;
  }
}
