@use "mixins" as *;

.dashboard-gradient {
  min-height: 270px;
  width: 100%;
  max-width: 1380px;
  background: linear-gradient(316.64deg, #1a3e6d 0%, #2d6fc6 107.95%);

  @media (min-width: 992px) {
    max-width: 900px;
  }

  @media (min-width: 1080px) {
    max-width: 950px;
  }

  @media (min-width: 1200px) {
    max-width: 1130px;
  }

  @media (min-width: 1350px) {
    max-width: 70%;
  }
}

.main-dashboard {
  .holding-section {
    padding: 30px 0px 110px 0px;
    position: relative;
    margin-top: -270px;

    .herotext {
      gap: 1rem;
    }
    .holding-curve {
      img {
        position: absolute;
        right: -12px;
        top: 0px;
        width: 240px;
        @include break(lg) {
          right: -78px;
        }
      }
    }
    .border-right {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
    .current-holding {
      padding-left: 50px;
    }
    .holding-amount {
      color: #fff;
      flex: 1;
      padding: 0 1rem;

      & + .holding-amount {
        border-left: 1px solid rgba(#fff, 0.2);
        padding-left: 2.5rem;
      }

      .holding-text {
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 35px;
        margin-bottom: 0px;
      }
      .amount-text {
        font-weight: bold;
        font-size: 1.875rem;
        line-height: 57px;
      }
    }
  }

  .retiralscrore-section {
    margin-top: -50px;
    position: relative;
    border-radius: 11px;
    .ill-slider {
      position: relative;
    }
    .add-investment {
      background: #1a3e6d;
      box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
      border-radius: 28px;
      padding: 16px 32px 16px 28px;
      font-size: 1.0625rem;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #f6f7f8;
      display: inline-block;
      position: absolute;
      top: -28px;
      width: 25%;
      margin: 0 auto;
      left: 0;
      right: 0;
      white-space: nowrap;
      outline: none;
      border-style: none;
      width: max-content;
      z-index: 80;
    }
    .retiral-slider-block {
      background: #ffffff;
      box-shadow: 0px 16px 64px -32px rgba(0, 0, 0, 0.24);
      border-radius: 11px;
      .slick-slider {
        position: relative;
      }

      .slick-dots {
        position: absolute;
        margin: 0.5rem 0 0 0 !important;
        left: 50%;
        transform: translateX(-50%) translateY(-12px);

        @include break(lg) {
          transform: translateX(-50%) translateY(-2.75rem);
        }

        li {
          &.slick-active {
            button {
              width: 1.4rem;
              border-radius: 2px;
            }
          }
          button {
            --size: 5px;
            animation: width 100ms ease;
          }

          & + li {
            margin-left: 4px;
          }
        }
      }
    }

    .retiral-slider {
      .last-updated {
        font-size: 0.8125rem;
        font-weight: 400;
        text-align: center;
        position: absolute;
        right: 1rem;
        top: 1rem;
      }

      .rectangle {
        width: 25px;
        height: 14px;
        border-radius: 99rem;
      }

      .Epfprice {
        font-size: 0.6875rem;
        font-weight: 400;
        color: #000000;
        text-align: left;
      }

      .link {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #2277f1;
        padding: 1rem 1rem;
      }

      .retiral-text {
        font-weight: 600;
        font-size: 0.875rem;

        color: #005e9e;
      }
      .retiral-discount {
        font-weight: 700;
        font-size: 3.75rem;
        display: block;
        color: #005e9e;
      }

      .retriral-discount-desc {
        font-size: 1rem;
        line-height: 24px;
        font-weight: normal;

        @media (min-width: 992px) {
          &::after {
            content: "";
            width: 1px;
            height: 50px;
            background-color: #d9dcdf;
            position: absolute;
            left: 0px;
            top: 0rem;
          }
        }
      }
      .retiral-text-small {
        font-size: 1.375rem;
        font-weight: 600;
        text-transform: capitalize;
        color: #1a3e6d;
        line-height: 1.3;
        .RS-head {
          font-size: 0.875rem;
          line-height: 0;
        }
        @include break(lg) {
          font-size: 1.125rem;
        }
      }
      .retiral-discount-small {
        font-size: 2.4375rem;
        line-height: 1;
        color: #1a3e6d;
        font-weight: 600;
      }

      .amount {
        .holdings-bullets {
          font-size: 0.6875rem;
          font-weight: 400;

          p {
            position: relative;
            padding-left: 1rem;
            text-align: left;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 12px;
              width: 7px;
              border-radius: 99rem;
              background: var(--accent);
            }
          }

          .ppf {
            --accent: #00c0f3;
          }

          .annuity {
            --accent: #2e77da;
          }

          .pf {
            --accent: #005e9e;
          }

          .mutual-fund {
            --accent: #4aaac1;
          }

          .nps {
            --accent: #1a3e6d;
          }

          .term-insurance {
            --accent: #7b5aa6;
          }
        }
      }
      .seconduser-amount {
        margin: 2rem auto 1rem;
        display: grid;
        row-gap: 1rem;
        max-width: 70%;

        @include break(lg) {
          grid-template-columns: 1fr 1fr;
          margin: 2rem 0 1rem;
          max-width: none;
        }
      }

      .discount-calulate-btn {
        border: 1px solid #1a3e6d;
        box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
        padding: 0.8rem 0.8rem;
        text-align: center;
        background: transparent;
        color: #1a3e6d;
        white-space: nowrap;
        border-radius: 35px;
        font-size: 0.9375rem;
        font-weight: 600;
        line-height: 24px;
        min-width: 140px;
      }
      .total-text {
        color: #2277f1;
        font-size: 1rem;
        font-weight: 300;
      }
      .total-amount {
        color: #000000;
        font-weight: 500;
        font-size: 1.125rem;
      }
    }
  }
  //   .titlewith-link {
  //     a {
  //       text-decoration: none;
  //       font-weight: 500;
  //       font-size: 1.125rem;
  //       line-height: 27px;
  //       text-transform: capitalize;
  //       color: #2277f1;
  //     }
  //   }
  .investing-section {
    margin-top: 40px;
    .swiper-pagination {
      bottom: -30px;
    }
    .section-title {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 27px;
      @include break(lg) {
        color: #1a3e6d;
      }
    }
    .investcardbox {
      background: $white;
      border-radius: 12px;
      padding: 20px;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;

      .comingsoon {
        position: absolute;
        top: 20px;
        right: 0px;
        width: 120px;
      }
      img {
        width: 40px;
        display: inline;
      }
      a {
        background: #ffffff;
        border: 1.54839px solid #005e9e;
        padding: 5px 19px;
        text-align: center;
        border-radius: 38px;
        background: #005e9e;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        text-decoration: none;
      }
      span {
        color: #005e9e;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 27px;
        width: 100%;
        display: block;
        padding: 15px 0;
        overflow: hidden;
        flex: 1;
      }

      p {
        color: #363d4e;
        font-size: 0.875rem;
        line-height: 18px;
        margin-bottom: 0px;
      }
    }
  }

  .healthymain-section {
    .healthy-box {
      .healthy-section {
        background-color: #0793a3;
        overflow: hidden;
        padding: 2.5rem 2rem;
        border-radius: 20px;
        height: 250px;
        position: relative;
        & > * {
          position: relative;
          z-index: 3;
        }
        .bg-girl {
          position: absolute;
          right: -1px;
          bottom: 0rem;
          width: 600px;
          z-index: 1;
          & > img {
            width: 100%;
          }
        }

        .healthy-section-datadiv {
          .healthy-text {
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 36px;
            color: #ffffff;
          }
          .healthy-description {
            font-weight: normal;
            font-size: 0.8125rem;
            line-height: 21px;
            color: #ffffff;
            width: 55%;
          }
          .healthy-btn {
            background: #fff;
            border-radius: 31.8932px;
            width: max-content;
            .link {
              color: #0793a3;
            }
            button {
              background: transparent;
              padding: 0.5rem 1.5rem;
              width: max-content !important;
              text-align: center;
              color: #0793a3;
              border: none;
              font-weight: 600;
              font-size: 0.875rem;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
  .healthy-mobile-section {
    display: none;
  }

  .service-corner-section {
    .swiper-pagination {
      bottom: -30px;
    }
    .Servicecorner-card-base {
      .Services-card {
        width: 100%;
        height: 180px;
        background: $white;
        padding: 1rem 3rem 1rem 1rem;
        border-radius: 12px;
        position: relative;
        text-align: left;
      }
      .icon-serviceC {
        width: 34px;
        height: auto;
      }
      .head-SCC {
        flex-grow: 1;
        height: 50px;
        overflow: hidden;
        font-family: Poppins;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 18px;
        color: #031022;
      }
    }

    .curve {
      transform: scaleX(-1);
      position: absolute;
      right: 0;
      top: 0;
      width: 100px;
    }

    .main {
      display: flex;
      padding: 1rem 1rem;
      width: 20%;
    }

    .arrowbutton {
      font-size: 1.25rem;
      width: 30px;
      height: 30px;
      padding: 0px;
      line-height: 0px;
      text-align: center;
      background: #1e3f6d;
      border-radius: 50%;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .commingsoon-tag-Sercor {
      color: #005e9e;
      font-weight: 500;
      font-size: 0.875rem;
      padding: 5px 5px;
      background: #c4f1fd;
      border-radius: 5.51335px;
      text-align: center;
      width: 100%;
      height: max-content;
      margin-top: 1rem;
      white-space: nowrap;
    }
  }

  .main-dashboard-rightpart {
    background: #fff;
    padding-left: 2rem;
    margin-top: -270px;
  }

  .comming-soon-section {
    display: none;
  }
}

// Mobile View
@media only screen and (max-width: 767px) {
  .main-dashboard {
    .holding-section {
      .current-holding {
        padding-left: 0px;
      }
      .holding-amount {
        padding: 0 1rem;

        & + .holding-amount {
          padding-left: 1rem;
        }

        .holding-text {
          font-size: 0.88rem;
          line-height: 1.5575;
        }
        .amount-text {
          font-size: 1.145rem;
          line-height: 1.2;
          display: inline-block;
          margin-top: 0.5rem;
        }
      }
    }
    .retiralscrore-section {
      .retiral-slider-block {
        padding: 1.5rem 1.5rem 2.25rem 1.5rem;
      }
      .add-investment {
        font-size: 1.0625rem;
      }
      .retiral-slider {
        text-align: center;
        .retriral-discount-desc {
          padding-left: 0px;
        }
      }
    }
    .healthymain-section {
      display: none;
    }

    .healthy-mobile-title {
      font-size: 1.125rem;
      font-weight: 600;
      color: #040c22;
    }

    .healthy-mobile-section {
      display: block;
      background-color: #008493;
      background-image: url("../images/Main-Dashbaord/1.png");
      position: relative;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 1.5rem;
      border-radius: 15px;
      background-position: right;

      .healthy-scrore-mobile {
        display: flex;
        .helathy-counter {
          font-weight: bold;
          font-size: 2rem;
          line-height: 48px;
          text-align: center;
          color: #16f6ff;
          padding-right: 10px;
          border-right: 1px solid rgba(255, 255, 255, 0.4);
        }
        .helathy-counter-text {
          font-weight: 500;
          font-size: 0.75rem;
          line-height: 18px;
          color: #ffffff;
          padding-left: 10px;
          padding-top: 7px;
        }
      }
      .healthy-text-mobile {
        margin-top: 25px;
        padding-bottom: 5px;
        font-weight: bold;
        font-size: 1.3125rem;
        line-height: 31px;
        color: #ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
      .healthy-description-mobile {
        font-weight: normal;
        font-size: 11.8984px;
        line-height: 18px;
        color: #ffffff;
      }
      .bms-part-row-mobile {
        margin-top: 30px;
        .Bms-part-mobile {
          p {
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 21px;
            color: #ffffff;
            margin-bottom: 0px;
          }
          span {
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 36px;
            color: #ffffff;
          }
        }
      }
      .explore-helathy-mobile {
        display: inline-flex;
        align-items: center;
        margin-top: 15px;
        background: #ffffff;
        border-radius: 26.4378px;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 21px;
        text-align: center;
        color: #2e98a4;
        padding: 0.65rem 1.75rem;
      }
    }
    .service-corner-section {
      display: none;
    }
    .investing-section {
      .investcardbox {
        filter: drop-shadow(0px 4px 64px rgba(0, 0, 0, 0.14));
        margin-bottom: 28px;
      }
    }
    .main-dashboard-rightpart {
      .banks-card-sction {
        .banks-size {
          .cards-button {
            display: none;
          }
          .bank-policy {
            font-size: 1.375rem;
            line-height: 32px;
          }
          .bank-policy-desc {
            font-size: 1.25rem;
            line-height: 28px;
          }
        }
        .willplan-card {
          .banks-size {
            min-height: 346px;
          }
        }
      }
    }

    .subscribe-section {
      display: none;
    }
    .comming-soon-section {
      display: block;
      background: #d3f6ff;
      padding: 25px 0px;
      .coming-soontext {
        font-weight: 600;
        font-size: 1.375rem;
        line-height: 32px;
        margin-bottom: 0px;
        color: #004343;
      }
      .coming-soon-desc {
        font-weight: normal;
        font-size: 1.125rem;
        line-height: 26px;
        display: block;
      }
    }
  }
}

.subscribe-section {
  background: linear-gradient(316.64deg, #1a3e6d 0%, #2d6fc6 107.95%);
  .subscribe-text {
    font-weight: 600;
    font-size: 3rem;
    line-height: 72px;
    text-transform: capitalize;
    color: #ffffff;
    padding-top: 60px;
  }
  a {
    padding-top: 75px;
  }
}

.comming-soon-section {
  display: none;
}
.investcardbox-grid {
  --bs-gutter-x: 1rem !important;
}

.leftline {
  @media (min-width: 992px) {
    .chart-outer::before {
      content: "";
      width: 1px;
      height: 180px;
      background-color: #d9dcdf;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.discount-border {
  border-right: 1px solid #d9dcdf;
}
