@use "./_mixins.scss" as *;
@import "./webapp-variables";

@media only screen and (max-width: 767px) {
  // Banner
  .banner-section {
    background: linear-gradient(97deg, #1a3e6d 0.61%, #2d6fc6 101.43%);
    padding-top: 60px;
    padding-bottom: 20px;

    .mobilebanner-image {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .banner-download-application {
      margin-top: 0px;
      p {
        font-size: 1.125rem;
        line-height: 27px;
        font-weight: normal;
        color: $white;
      }
      img {
        width: 136px;
        height: 45px;
        padding-right: 10px;
      }
    }
  }

  // Products Section
  .products-container {
    .products-section {
      background: $powderblue;
      border-radius: 0px;
      padding-bottom: 60px;
      padding-top: 60px;
      margin: 0 0 !important;

      .swiper .swiper-pagination {
        width: 100% !important;
      }

      .title-row {
        padding-bottom: 0px;
        .title-text {
          @include title(18px, 27px, 600, $darkblue);
        }
      }
      .explore-btn {
        @include buttons(14px, 600, $lightwhite, $darkblue, 28px);
        padding: 10px 25px;
        margin-top: 10px;
      }
      .col-sm-15 {
        flex: 0 0 auto;
        width: 100%;
      }

      .products-box {
        position: relative;
        .products-detail {
          background: #ffffff;
          padding: 15px 0px;
          border-radius: 0.5rem;
          .comingsoon-img {
            position: absolute;
            top: 0;
            right: 0;
            width: 31px;
          }
          .product-img {
            width: 40px;
            height: 40px;
            margin: 0 auto;
          }
          .products-category {
            color: $lightblue;
            font-size: 0.5625rem;
            line-height: 24px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 8px 0px 0px 0px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.dashboard-piechart {
  height: 170px;
  width: 170px;
  margin: 0 auto;
  position: relative;

  .retiral-score-abs {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
