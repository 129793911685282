@use "../../../assets/styles/_mixins.scss" as *;

.faqmobile-section {
  padding: 8rem 0 5rem;

  .accordion {
    border: none !important;
  }

  .title-text {
    font-weight: 600;
    font-size: 1.125rem;

    & + p {
      font-size: 0.875rem;
      font-weight: 400;
      color: #363d4e;
      margin-top: 10px;
    }
  }

  //   Accordions
  .accordions-holder {
    background-color: #f1f7ff;
    border-radius: 16px;

    .accordion-item {
      background-color: transparent;
      .accordion-header {
        .accordion-button {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 24px;
          border-radius: 1rem;
          //color: #263238;
          background-color: transparent;
          padding: 0.75rem 1.25rem;

          &:not(.collapsed) {
            background: transparent;
          }
        }
      }

      & + .accordion-item {
        margin-top: 11px;
      }

      .accordion-body {
        font-size: 0.875rem;
        color: #363d4e;
        padding: 0.5rem 1.25rem;
      }
    }
  }
}

.faq-desktop-section {
  background-color: #fff;

  .title-text {
    font-weight: 600;
    font-size: 2.25rem;
    color: #1e3f6d;
    margin-bottom: 4rem;
  }

  .accordion {
    border: none;

    .accordion-item {
      &.active {
        .title {
          color: #3282c4;
          margin-bottom: 0.5;
          &::after {
            content: url("data:image/svg+xml,%0A%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.625 12.1875L10.3125 7.5L5.625 2.8125' stroke='%231E3F6D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            transform: rotate(90deg);
          }
        }
      }
      .title {
        background-color: #fff;
        padding: 5px;
        font-weight: 600;
        font-size: 1.125rem;
        color: #222324;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        position: relative;

        &::after {
          content: url("data:image/svg+xml,%0A%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.625 12.1875L10.3125 7.5L5.625 2.8125' stroke='%231E3F6D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          float: left;
          margin-right: 1rem;
          position: absolute;
          right: 0;
          top: 2px;
        }
      }

      & + .accordion-item {
        margin-top: 0.25rem;
      }

      .panel {
        padding-left: 6px;
        margin-right: 8rem;
        background-color: #fff;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 24px;
        color: #312e2e;
      }
    }
  }
}
.faq-btn {
  color: #1a3e6d;
  font-weight: 600;
  font-size: 0.875rem;
  border: 1px solid #1a3e6d;
  border-radius: 99rem;
  padding: 12px 25px;
}
