.signupmain {
  width: 100%;
  height: 100%;
  background: #e5e5e5;
}
.accept-text {
  font-size: 0.9375rem;
}

.signleft {
  width: 55%;
  float: left;
  background: #f2f3f7;
  padding: 50px 50px;
  min-height: 100vh;
  span {
    button {
      line-height: 1;
      padding: 0;
      font-size: 1rem;
      text-transform: unset;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 18px;
      color: #005e9e;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
        background: transparent;
      }
    }
  }
}

.signright {
  background: linear-gradient(318.22deg, #17325c 18.88%, #4c92dd 99.74%);
  padding: 0px 50px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  h3 {
    font-size: 2.625re;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
    a {
      color: #53bcd4;
    }
  }
  img {
    width: 480px;
  }
}
.Logo {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signuptitle {
  span {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 36px;
    color: #1e3f6d;
  }
  p {
    color: #263238;
    font-size: 1.25rem;
  }
}

.otp_row {
  width: min-content !important;
  .resentotp {
    padding-top: 30px;
    padding-bottom: 30px;
    color: #005e9e;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    width: 100%;
    a {
      font-size: 1rem;
      color: #005e9e;
    }
  }
  .timer_text {
    padding-right: 10px !important;
  }
}

.subbtn {
  padding: 16px 32px;
  color: #fff;
  background: #1a3e6d;
  box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
  border-radius: 40px;
  border: none;
}

.setpassword {
  padding-top: 10px;
  Label {
    color: #1a3e6d;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 10px;
    letter-spacing: 0.4px;
  }
  input[type="password"] {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    color: #1a3e6d;
    outline: none;
    box-shadow: none;
    margin-bottom: 16px;
  }
  .subbtn {
    margin-top: 47px;
  }
}

.signupfrm {
  Label {
    color: #1a3e6d;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 10px;
    letter-spacing: 0.4px;
  }
  input[type="text"],
  input[type="number"] {
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    color: #1a3e6d;
    outline: none;
    box-shadow: none;
    // margin-bottom: 8px;
  }

  input[type="submit"] {
    padding: 12px 32px;
    color: #fff;
    background: #1a3e6d;
    box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
    border-radius: 40px;
    border: none;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  a {
    color: #005e9e;
    font-weight: bold;
  }
}
.btnsubtitle {
  color: #5c616f;
  font-size: 0.875rem;
  font-weight: bold;
  margin-top: 10px;
}

.btn-login {
  padding: 12px 32px !important;
  color: #005e9e !important;
  background-color: #ffffff !important;
  border-radius: 40px !important;
  border: 1.5px solid #005e9e !important;
  margin-top: 30px !important;
  margin-bottom: 20px !important;
  max-width: max-content;
  height: 50px;
  white-space: nowrap;
}

// Login
.loginfrm {
  .loginotptitle {
    h4 {
      font-size: 0.875rem;
      font-weight: 600;
      color: #1e3f6d;
      width: 100%;
    }
  }
  label {
    color: #1a3e6d;
    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.4px;
    margin-bottom: 0.5rem;
  }
  input[type="text"],
  input[type="number"],
  input[type="password"] {
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem;
    line-height: 1.5;
    background: #ffffff;
    letter-spacing: 0.2px;
    box-sizing: border-box;
    border-radius: 8px;
    color: #040c22;
    outline: none;
    box-shadow: none;
    font-size: 1rem;
    font-weight: 400;
  }

  .loginbtn {
    padding: 12px 32px;
    color: #fff;
    background: #1a3e6d;
    box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
    border-radius: 40px;
    border: none;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

// Loading Page
.loadingbg {
  background: #e5e5e5;
  padding: 50px;
  min-height: 100vh;
  &::after,
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
  }
  &::after {
    background: url(../images/loadingtop.svg) top right;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
  }
  &::before {
    background: url(../images/loadingbottom.svg) bottom left;
    background-repeat: no-repeat;
    bottom: 0;
    left: 0;
  }

  .medialbox {
    z-index: 999;
    width: 300px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    span {
      width: 100%;
      display: inline-block;
      font-size: 1.125rem;
      line-height: 32px;
      text-align: center;
      color: #263238;
      padding-top: 30px;
      padding-bottom: 14px;
    }
    a {
      color: #005e9e;
      font-size: 1.125rem;
      line-height: 32px;
      text-align: center;
      width: 100%;
      display: inline-block;
    }
  }
}

.otpInput {
  margin-right: 1rem;
  input {
    width: 75px !important;
    height: 75px;
    padding: 0.5rem 0rem;
    outline: none;
    border-style: none;
    background: #ffffff;
    // border: 1.29px solid #cdd4db;
    box-shadow: 0px 10.32px 30.96px -7.74px rgba(0, 0, 0, 0.04);
    border-radius: 10.32px;
    color: #040c22;
    font-weight: normal;
    font-size: 1.955rem;
  }
}
.BTN_intro {
  padding: 12px 32px;
  color: #fff;
  background: #1a3e6d;
  box-shadow: 0px 8px 16px -6px (0 82 103 / 32%);
  border-radius: 40px;
  border: none;
  margin-top: 40px;
}
.reg_text {
  font-weight: 500;
  font-size: 0.875rem;
  color: #5c616f;
}

.logo_login {
  width: 100px;
}

@media only screen and (max-width: 767px) {
  .signupmain {
    .signleft {
      width: 100%;
      padding: 50px 20px;
    }
  }
  .otpInput input {
    width: 100% !important;
    height: 100%;
  }

  .signupfrm input[type="submit"],
  .loginfrm .loginbtn,
  .subbtn {
    width: 100%;
  }
  .signleft {
    background: $white;
  }
  .signleft .otpInput:last-child {
    margin-right: 0;
  }
}

.SigninBtn {
  padding: 12px 32px !important;
  color: #fff !important;
  background: #1a3e6d !important;
  box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32) !important;
  border-radius: 40px !important;
  border: none !important;
  margin-top: 30px !important;
  margin-bottom: 20px !important;
}
.signuprow {
  --bs-gutter-x: 5rem !important;
  margin-right: 2rem;
}

.password-visibility-icon {
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
