.bg-plan {
  background-color: #f2f3f7;
}

.GoalPlanner {
  background-image: url(../images/GoalPlanner.svg);
  background-size: cover;
  height: 283px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 5rem;
  padding-top: 3rem;
  margin-top: 1.5rem;
  border-radius: 16px;
  h2 {
    font-weight: 600;
    font-size: 1.5rem;
    color: #263238;
  }
  p {
    font-weight: normal;
    font-size: 1.125rem;
    color: #2b2b2b;
    width: 55%;
  }
  .plangoalbtn {
    background: #1a3e6d;
    box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
    border-radius: 28px;
    color: #f6f7f8;
    font-weight: 500;
    font-size: 1.0625rem;
    padding: 0.75rem 1.5rem;
    outline: none;
    border-style: none;
    width: max-content;
  }
}
.GoalPlannerMV {
  background-image: url(../images/Goalcard_MV.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 280px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 3rem;
  padding-top: 3rem;
  margin-top: 1.5rem;
  border-radius: 16px;
  h2 {
    font-weight: 600;
    font-size: 1.5rem;
    color: #263238;
  }
  p {
    font-weight: normal;
    font-size: 1.125rem;
    color: #2b2b2b;
    width: 70%;
  }
  .plangoalbtn {
    background: #1a3e6d;
    box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
    border-radius: 28px;
    color: #f6f7f8;
    font-weight: 500;
    font-size: 1.0625rem;
    padding: 0.75rem 1.5rem;
    outline: none;
    border-style: none;
    width: max-content;
  }
}

.SelectGoalMV {
  .FGP_MV {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .link {
      padding: 20px 0px 0px 0px;
    }
  }
  .card_gride {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
    .card_items {
      width: max-content;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 0.5rem 1rem;
      width: 100%;
      background-color: #fff;
      border-radius: 12px;
      box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.15);
      img {
        width: 32px;
        height: 32px;
      }
      h2 {
        font-weight: 500;
        font-size: 0.9375rem;
        color: #005e9e;
      }
    }
  }
}
.SelectGoalslider-MV {
  background-image: url(../images/BG_img_NF_goal.svg);
  background-size: cover;
  border-radius: 9px;
  background-repeat: no-repeat;
  width: 100%;
  padding: 1rem;
  img {
    width: 35px;
    height: 35px;
    margin-bottom: 0.5rem;
  }
  p {
    text-align: left;
    color: #fff;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

.TermFuturePlanning {
  .graph_card {
    .card_head {
      background: linear-gradient(134.14deg, #1a3e6d 0.61%, #2d6fc6 101.43%);
      border-radius: 16px 16px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5rem 0rem;
      h2 {
        text-align: center;
        color: #fff;
        font-weight: 500;
        font-size: 1.75rem;
        width: 80%;
      }
    }
    .div_text {
      box-shadow: 0px 16px 100px -40px rgba(0, 0, 0, 0.08);
      border-radius: 0px 0px 16px 16px;
      .T-div {
        padding: 0.5rem 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #000;
        p {
          margin: 0;
          font-weight: normal;
          font-size: 0.875rem;
          color: #363d4e;
        }
      }
      .D-div {
        padding: 1rem 1.5rem;
        background-color: #fff;
        .top {
          display: flex;
          align-items: center;
          gap: 1rem;
          .dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #2277f1;
          }
          p {
            margin: 0px;
            font-weight: normal;
            font-size: 1rem;
            color: #225290;
          }
          h2 {
            margin: 0px;
            font-weight: bold;
            font-size: 1.5rem;
            color: #2277f1;
          }
        }
      }
      .down {
        padding: 1.5rem 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        background: #f2f3f7;
        border-radius: 0px 0px 16px 16px;
        .p_one {
          font-weight: 600;
          font-size: 1rem;
          color: #245799;
          margin-bottom: 0px;
        }
        p {
          font-weight: normal;
          font-size: 1rem;
          color: #225290;
        }
      }
    }
  }
  .BookSlot {
    width: 100%;
    .bookslottop {
      padding: 1.5rem;
      padding-bottom: 2.5rem;
      display: flex;
      background: linear-gradient(134.14deg, #1a3e6d 0.61%, #2d6fc6 101.43%);
      gap: 3rem;
      border-radius: 16px 16px 0px 0px;
      .mid-data {
        h2 {
          color: #fff;
          width: 85%;
          padding-bottom: 1rem;
          font-size: 1.5rem;
          font-weight: 600;
        }
        .callN-text {
          color: #f6f7f8;
          font-weight: 500;
          font-size: 1.0625rem;
          padding-bottom: 0.75rem;
          width: max-content;
          span {
            color: #fcfcfc;
            font-size: 0.875rem;
            font-weight: 300;
          }
        }
        .border-b {
          border-bottom: 1px dashed #acacac;
        }
        .icon-text {
          display: flex;
          gap: 1.5rem;
          .icon-item {
            display: flex;
            color: #fff;
            gap: 0.5rem;
            p {
              margin: 0;
            }
          }
        }
      }
      .btn_div {
        white-space: nowrap;
        button {
          background: #1a3e6d;
          box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
          border-radius: 28px;
          color: #f6f7f8;
          font-weight: 600;
          font-size: 1.0625rem;
          padding: 0.75rem 1.25rem;
          outline: none;
          border-style: none;
        }
      }
    }
  }
  .advisor_div {
    background-color: #fff;
    border-radius: 16px;
    margin-top: -13px;
    h2 {
      font-weight: 600;
      font-size: 1.125rem;
      color: #263238;
      padding: 1rem 1rem 0rem 1rem;
      margin: 0px;
    }
    hr {
      background-color: #b9e3f1;
    }
    .icon-group {
      padding: 0.5rem 1rem;
      display: flex;
      .icon-data-group {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: max-content;
        img {
          width: 32px;
          height: 32px;
        }
        p {
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 21px;
          text-transform: capitalize;
          color: #263238;
          width: 75%;
        }
      }
    }
  }
  .BookMV {
    background: linear-gradient(134.14deg, #1a3e6d 0.61%, #2d6fc6 101.43%);
    box-shadow: 0px 16px 64px -32px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    color: #fff;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      img {
        width: 120px;
      }
      h2 {
        font-weight: 600;
        font-size: 1rem;
        line-height: 21px;
        text-transform: capitalize;
        color: #ffffff;
      }
    }
    .mid-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .line_p {
        padding: 5px;
        border-right: 1px solid #fff;
      }
    }
    .btn_div {
      white-space: nowrap;
      text-align: center;
      button {
        background: #1a3e6d;
        box-shadow: 0px 8px 16px -6px rgba(0, 82, 103, 0.32);
        border-radius: 28px;
        color: #f6f7f8;
        font-weight: 600;
        font-size: 1.0625rem;
        padding: 0.75rem 1.25rem;
        outline: none;
        border-style: none;
      }
    }
  }
}

.Plan_mv {
  display: none;
}
@media only screen and (max-width: 600px) {
  .Plan_mv {
    display: block;
  }
}
