.PdfPortfolio-main {
  height: 100vh;

  .retiral-score-abs {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #005e9e;

    .RS-head {
      font-weight: 600;
      font-size: 1.25rem;
    }

    .retiral-text-small {
      font-weight: 600;
      font-size: 1.875rem;
    }
  }

  .product-grid {
    display: grid;
    grid-template-columns: 285px 285px;
    row-gap: 1.25rem;
    column-gap: 2rem;

    .cricle {
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }

    .product-name {
      font-size: 1.25rem;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}
