// Blue Color
$darkblue: #1e3f6d;
$blue: #eaf7fb;
$lightblue: #005e9e;
$powderblue: #e5f4ff;
$brandblue: #3282c4;
$skyblue: #00c0f3;

// White Color
$white: #ffffff;
$lightwhite: #f6f7f8;
$powderwhite: #fafafa;

// Black & Gray color
$lightgray: #808285;
$darkgray: #312e2e;

// Gradiant Color
$gradiantrainforest: linear-gradient(90deg, #28d1ca, #4470a6);
$gradiantlightrainforest: linear-gradient(180deg, #28d1ca, #4470a6);
$commservices: url(images/Telecom-Financial-Service.jpg);

// Font Family
$FontRegular: "Helvetica";
$FontLight: "HelveticaNeue-Light";
$FontMedium: "HelveticaNeue-Medium";
