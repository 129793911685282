.Offerdetail-copmonent {
  background: #e5e5e5;
  height: 100vh;
  .pharma-details {
    padding-top: 50px;
    padding-left: 50px;
  }
  .flat-text {
    font-weight: bold;
    font-size: 3.70833125rem;
    line-height: 89px;
    color: #189272;
  }
  .discount-text {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 32px;
    text-transform: capitalize;
    color: #263238;
  }
  .avail-btn {
    background: #189272;
    box-shadow: 0px 7.01086px 28.0434px rgba(175, 175, 175, 0.15);
    font-size: 1.5625rem;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: -0.4527845084667206px;
    color: #ffffff;
    padding: 22px 3rem;
    border-radius: 99rem;
  }

  // list styles
  .pharma-ul {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 48px;
    list-style: none;
    padding: 0;

    li {
      font-size: 1.125rem;
      line-height: 27px;
      padding-left: 2rem;
      position: relative;

      &::before {
        content: "";
        --size: 12px;
        height: var(--size);
        width: var(--size);
        background: #189272;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      & + li {
        margin-top: 26px;
      }
    }
  }

  .MuiTabs-root {
    display: inline-flex;
    background: #eeeeee;
    border-radius: 1.5rem;
    padding: 0.25rem;

    .MuiButtonBase-root {
      font-size: 1.1875rem;
      font-weight: 500;
      line-height: 28px;
      text-transform: none;
      padding: 6px 20px;
      border-radius: 1.5rem;
      color: #7b7b7b;

      &.Mui-selected {
        background: #fff;
        font-weight: 600;
        color: #189272;
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }
}
